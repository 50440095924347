import {motion} from "framer-motion";

export default function CarouselItem({ origem, imagem, active, duration }) {
    
    return (
        <div class={`carousel-item ${active}`} data-bs-interval="4000">
            <div class="card text-bg-light border-light carta ">
                <img src={imagem} class="card-img" alt="..." />
                {/*<div className='card-img-overlay conteudo justify-content'>{origem}</div>*/}
                <motion.div
                    initial={{ x: window.innerHeight }}
                    animate={{ x: 0 }}
                    transition={{ duration: duration }}
                    exit={{ width: 100 }}
                    class="card-img-overlay conteudo justify-content">
                    <div>{origem}</div>
                </motion.div>
            </div>
        </div>
    )
}
