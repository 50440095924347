import React from 'react';
import "../Banner/banner.css"

function Banner(){

    return <section id='banner'>
        <div className='container-fluid contentor' >                       
        <div id="carouselExampleFade" class="carousel slide carousel-fade"data-bs-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item active">
            <img src="./Images/Cap.jpg" class="d-block w-100" alt="..."/>
            </div>
            <div class="carousel-item">
            <img src="./Images/Capa_6.png" class="d-block w-100" alt="..."/>
            </div>
            <div class="carousel-item">
            <img src="./Images/Capa_7.png" class="d-block w-100" alt="..."/>
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
        </div>
        </div>
    </section>   
}
export default Banner;