import React from 'react';
import "../Historia/historia.css";
import CarouselItem from './components/CarouselItem';
import { origem as o, visao as v, missao as m, valores as val} from '../../Shared/Content'

function Historia() {
    const listalores = () => {
        
        return (
            <ul>
                {val.map((item) => <li key={item}>{item}</li>)}
            </ul>
        )
    }

    return <section id='historia'>
        <div className='container-fluid contentor'>

            <div className='titulo'>
                <h1>QUEM SOMOS</h1>
                <h4>Transparência e Flexibilidade</h4>
                <p></p>
                <br></br>
            </div>

            <div id="carouselExampleControlsNoTouching" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner ">
                    <CarouselItem origem={o} imagem="./Images/origem.png" active="active" duration={3}/>
                    <CarouselItem origem={v} imagem="./Images/visao.png" active="" duration={3}/>
                    <CarouselItem origem={m} imagem="./Images/missao.png" active="" duration={3}/>
                    <CarouselItem origem={listalores()} imagem="./Images/valores.png" active="" duration={3}/>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>

        </div>
    </section>
}
export default Historia;
