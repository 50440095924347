import React from 'react'
import "../Equipe/equipe.css";
import { backIn, motion } from 'framer-motion';

function equipe() {

  const foto={maxWidth:"540px"}
  const equipe="Nossa equipe é formada por profissionais altamente qualificados e principalmente com paixão na gestão das necessidades do cliente. Nosso modelo operacional baseado nos princípios FAIR (Fácil, Acessível, Integrado, Responsável e Rápido) nos permite ter uma excelente qualidade no atendimento e prestação do serviço eficiente garantindo satisfação do cliente."

  return <section id='equipe'>

    <div className='container '>

                <motion.div className='titulo'
                   initial={{x:-1000}}
                   animate={{x:0}}
                   transition={{
                     duration:3,
                     delay:0.2,
                     repeat:0
                   }}
                   whileHover={{scale:1.02,color:"#0f0f0f",rotate: 3,}}
                   whileInView={{
                    x: -28,
                    y: 27,
                    scale: 1.2,
                                    
                   }}
                
                  
                  >
                    

                <h1>Nossa Equipe</h1>
                <h4>Competência e Compromentimento </h4>
                <p></p>
                <br></br>
                </motion.div>

              <div class="card mb-3 text-bg-light border-light" >
              <div class="row g-0 conteudoE ">
               
                <div class="col-md-8 corpoC ">
                  <div class="card-body">
                    <h5 class="card-title"></h5>
                    <motion.p class="card-text justify-contentor"
                      initial={{x:-1000}}
                      animate={{x:0}}
                      transition={{
                        duration:3,
                        delay:0.2,
                        repeat:0
                      }}
                      whileHover={{}}
                      whileInView={{
                       
                        rotateX:360
                         
                      }}
                    >
                      {equipe}
                    </motion.p>
                    </div>
                </div>
                <div class="col-md-4">

                <div id="carouselExampleFade1" class="carousel slide carousel-fade" data-bs-ride="carousel">
                        <div class="carousel-inner">
                          <div class="carousel-item active">
                          <img src="./Images/secre_1.jfif" class="img-fluid rounded-start" alt="..."/>
                          </div>
                          <div class="carousel-item">
                          <img src="./Images/secre_2.jfif" class="img-fluid rounded-start" alt="..."/>
                          </div>
                          <div class="carousel-item">
                          <img src="./Images/secre_3.jfif" class="img-fluid rounded-start" alt="..."/>
                          </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade1" data-bs-slide="prev">
                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade1" data-bs-slide="next">
                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                          <span class="visually-hidden">Next</span>
                        </button>
                      </div>
                 </div>
              </div>
            </div>

        
    </div>
    </section>
  
}

export default equipe
