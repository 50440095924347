import React from 'react'
import "../../Footer/footer.css"



export default function redesSociais() {
   
  
    return (
        <div>
        <h5>Nossas Redes Sociais</h5><br></br>
                                                                      
        <ul className=' list-unstyled list-inline social text-start '>
            <li className='list-inline-item'><a href=''target="_blank"><i className="fa fa-facebook fa-2x"id="fa"></i></a></li>
            <li className='list-inline-item'><a href='#'target="_blank"><i className=" fa fa-instagram fa-2x rede"id="ins"></i></a></li>
            <li className='list-inline-item'><a href='#'target="_blank"><i className="fa fa-twitter fa-2x rede"id="tw"></i></a></li>
            <li className='list-inline-item'><a href='mailto:fairflex@gmail.com' target="_blank"><i className=" fa fa-envelope fa-2x rede"id="mai"></i></a></li>
        </ul>
        
        <br></br>
        <h5>Oferecemos servicos de Transportes escolar privado</h5>
        <br></br>   
        <h6>Pacote Exclusivo e Partilhado<br></br><br></br>
        Atendemos em todas principais rotas da cidade e provincia de Maputo </h6>
        </div>
    )
}
